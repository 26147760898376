<template>
  <div>
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav v-if="!getUrlParameter('programa') && !getUrlParameter('turma')">
          <ul class="fd-app-breadcrumb fast-menu-lives-aluno-fast">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma"
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos')"
              >Meus cursos</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Detalhes do curso</a>
            </li>
          </ul>
          <ul class="fd-app-breadcrumb d-none fast-menu-lives-aluno-iuea">
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Menu principal</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos')"
              >Meus cursos</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Detalhes do curso</a>
            </li>
          </ul>
        </nav>
        <nav v-else>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma"
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turmas'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turmas')"
              >Minhas turmas</a>
            </li>            
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + parseInt(base64decode(getUrlParameter('turma'))) + '/programa/' + parseInt(base64decode(getUrlParameter('programa'))) + '?matricula=' + base64encode(parseInt(base64decode(getUrlParameter('matricula'))))"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + parseInt(base64decode(getUrlParameter('turma'))) + '/programa/' + parseInt(base64decode(getUrlParameter('programa'))) + '?matricula=' + base64encode(parseInt(base64decode(getUrlParameter('matricula')))))"
              >Turma</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Detalhes da unidade curricular</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div>
        <div
          id="body_conteudo_modo_ead"
          class="fd-app-wrap pt-0"
        >
          <input
            id="hdnDataInicioCurso"
            type="hidden"
            value
          >
          <input
            id="hdnDataFimCurso"
            type="hidden"
            value
          >
          <!-- fd-wrap -->
          <div class="con-wrap">
            <div class="con-content">
              <div class="container">
                <div class="mt-3 mb-4">
                  <a
                    v-if="getUrlParameter('programa') && getUrlParameter('turma')"
                    class="btn-novo btn-red-hollow-novo fast-iuea-ml-20"
                    :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + parseInt(base64decode(getUrlParameter('turma'))) + '/programa/' + parseInt(base64decode(getUrlParameter('programa'))) + '?matricula=' + base64encode(parseInt(base64decode(getUrlParameter('matricula'))))"
                    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + parseInt(base64decode(getUrlParameter('turma'))) + '/programa/' + parseInt(base64decode(getUrlParameter('programa'))) + '?matricula=' + base64encode(parseInt(base64decode(getUrlParameter('matricula')))))"
                  >
                    <b-icon-arrow-return-left /> Voltar
                  </a>
                  <a
                    v-else
                    class="btn-novo btn-red-hollow-novo fast-iuea-ml-20"
                    :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos'"
                    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos')"
                  >
                    <b-icon-arrow-return-left /> Voltar
                  </a>
                </div>
                <div v-if="fastCarregamentoCompleto" class="row justify-content-center">
                  <div class="col-12">

                    <!-- con-welcome-curso -->
                    <section>
                      <div v-if="fastCursoPlataforma.logo_curso" id="con-logo-curso" class="col-sm-12 col-md-12 col-lg-12" :style="{ 'background-image': 'url(' + ajustaLinkApiAntiga(fastCursoPlataforma.logo_curso) + ')', 'background-position' : 'center', 'background-repeat' : 'no-repeat'}"></div>
                      <h3> {{ fastCursoPlataforma.nome_curso }} </h3>
                      <p> {{ fastCursoPlataforma.detalhe_curso }} </p>
                    </section>
                    <!-- /con-welcome-curso -->

                    <!-- con-timeline-curso-novo -->
                    <section class="con-timeline-curso-novo mb-2 fast-iuea-bgcolor">
                      <div class="con-timeline-curso-novo-list">
                        <!-- Video Aulas -->
                        <div
                          v-if="fastCursoDetalhes.numero_videos"
                          class="con-timeline-curso-novo-list-item"
                        >                          
                          <b-icon-collection-play-fill
                            font-scale="3"
                            class="text-blue-novo"
                          />
                          <p class="text-blue-novo">
                            <span class="text-orange"
                              id="curso_numero_videos"
                              style
                            >{{ fastCursoDetalhes.numero_videos }}</span> Video Aulas
                          </p>
                        </div>
                        <!-- Exercícios -->
                        <div
                          v-if="fastCursoDetalhes.numero_quizes"
                          class="con-timeline-curso-novo-list-item"
                        >
                          <b-icon-ui-checks
                            font-scale="3"
                            class="text-blue-novo"
                          />
                          <p class="text-blue-novo">
                            <span class="text-orange"
                              id="curso_numero_quizes"
                              style
                            >{{ fastCursoDetalhes.numero_quizes }}</span> Quizes
                          </p>
                        </div>
                        <!-- Audio Aulas -->
                        <div
                          v-if="fastCursoDetalhes.numero_audios"
                          class="con-timeline-curso-novo-list-item"
                        >
                          <b-icon-volume-up
                            font-scale="3"
                            class="text-blue-novo"
                          />
                          <p class="text-blue-novo">
                            <span class="text-orange"
                              id="curso_numero_audios"
                              style
                            >{{ fastCursoDetalhes.numero_audios }}</span> Audio Aulas
                          </p>
                        </div>
                        <!-- Arquivos -->
                        <div
                          v-if="fastCursoDetalhes.numero_arquivos"
                          class="con-timeline-curso-novo-list-item"
                        >
                          <b-icon-file-earmark-text
                            font-scale="3"
                            class="text-blue-novo"
                          />
                          <p class="text-blue-novo">
                            <span class="text-orange"
                              id="curso_numero_arquivos"
                              style
                            >{{ fastCursoDetalhes.numero_arquivos }}</span> Arquivos
                          </p>
                        </div>
                        <!-- Lives -->
                        <div
                          v-if="fastCursoDetalhes.numero_lives"
                          class="con-timeline-curso-novo-list-item"
                        >
                          <b-icon-camera-video
                            font-scale="3"
                            class="text-blue-novo"
                          />
                          <p class="text-blue-novo">
                            <span class="text-orange"
                              id="curso_numero_quizes"
                              style
                            >{{ fastCursoDetalhes.numero_lives }}</span> Lives
                          </p>
                        </div>
                        <!-- Aula mestre -->
                        <div
                          v-if="fastCursoDetalhes.numero_aula_mestre"
                          class="con-timeline-curso-novo-list-item"
                        >
                          <b-icon-camera-video
                            font-scale="3"
                            class="text-blue-novo"
                          />
                          <p class="text-blue-novo">
                            <span class="text-orange" id="curso_numero_aula_mestre">
                              <img
                                src
                                style="width: 50px"
                              >
                            </span>
                          </p>
                        </div>
                        <!-- Pesquisas -->
                        <div
                          v-if="fastCursoDetalhes.numero_pesquisas"
                          class="con-timeline-curso-novo-list-item"
                        >
                          <b-icon-clipboard-data
                            font-scale="3"
                            class="text-blue-novo"
                          />
                          <p class="text-blue-novo">
                            <span class="text-orange"
                              id="curso_numero_pesquisas"
                              style
                            >{{ fastCursoDetalhes.numero_pesquisas }}</span> Pesquisas
                          </p>
                        </div>
                        <!-- Avaliações -->
                        <div
                          v-if="fastCursoDetalhes.numero_avaliacoes"
                          class="con-timeline-curso-novo-list-item"
                        >
                          <b-icon-stopwatch
                            font-scale="3"
                            class="text-blue-novo"
                          />
                          <p class="text-blue-novo">
                            <span class="text-orange"
                              id="curso_numero_avaliacoes"
                              style
                            >{{ fastCursoDetalhes.numero_avaliacoes }}</span> Avaliações
                          </p>
                        </div>
                        <!-- Certificado -->
                        <div
                          v-if="fastCursoCertificado"
                          class="con-timeline-curso-novo-list-item mr-4"
                        >
                          <b-icon-award
                            font-scale="3"
                            class="text-blue-novo"
                          />
                          <p class="text-blue-novo">
                            <span class="text-orange" id="curso_certificado"></span>Certificado
                          </p>
                        </div>
                      </div>
                    </section>
                    <section class="con-progress-curso">
                      <div class="progress position-relative mb-3">
                        <div class="progress-bar" role="progressbar" :style="{ width: fastCursoPorcentagem + '%'}" :aria-valuenow="fastCursoPorcentagem" aria-valuemin="0" aria-valuemax="100"></div>
                        <span class="justify-content-center d-flex position-absolute w-100">{{ isNaN(fastCursoPorcentagem) ? 0 : fastCursoPorcentagem  }}%</span>
                      </div>
                    </section>
                    <!-- /con-timeline-curso-novo -->

                    <section class="con-info-curso mt-2">
                      <div class="row justify-content-between">
                        <div class="col-sm-12 col-md-12 col-lg-12">

                          <!-- con-info-curso-actions-novo -->
                          <div v-if="!iniciandoCursoLoading" :class="!fastCursoIniciado ? '' : 'col-12'">
                            <div v-if="!fastCursoIniciado">
                              <div class="con-info-curso-actions-novo">
                                <a
                                  href="#"
                                  class="btn-novo btn-start-curso-novo btn-lg btn-block d-flex justify-content-center"
                                  @click.prevent="iniciaFastUsuarioCurso()"
                                >
                                  <img :src="require('@/assets/images/app/start-btn-curso.png')">
                                  <h3>Iniciar unidade curricular</h3> 
                                </a>
                              </div>
                            </div>
                            <div v-else class="row">                            
                              <div class="col-lg-7 col-md-12 col-sm-12 con-info-curso-actions-novo">
                                <div class="row mb-3">
                                  <a
                                    href="#"
                                    class="col-12 btn-novo btn-start-curso-novo btn-lg d-flex justify-content-center"
                                    @click.prevent="continuarCurso()"
                                  >
                                    <img :src="require('@/assets/images/app/start-btn-curso.png')">
                                    <h3>Continuar de onde parou</h3>
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-5 col-md-12 col-sm-12 con-info-curso-actions-novo"
                                v-if="fastCursoReiniciar"
                              >
                                <div class="row ml-lg-2">
                                  <a
                                    href="#"
                                    class="col-12 btn-novo btn-reset-curso-novo btn-lg d-flex justify-content-center"
                                    @click.prevent="showModal('modalReiniciarCurso')"
                                  >
                                    <img :src="require('@/assets/images/app/reset-btn-curso.png')">
                                    <h3>Recomeçar do zero</h3> 
                                  </a>
                                </div>
                              </div>  
                              <div class="col-lg-5 col-md-12 col-sm-12 con-info-curso-actions-novo"
                                v-else
                              >
                                <div class="row ml-lg-2">
                                  <a
                                    href="#"
                                    class="col-12 btn-novo btn-reset-curso-novo btn-reset-curso-novo-disabled btn-lg d-flex justify-content-center"
                                    @click.prevent
                                  >
                                    <img :src="require('@/assets/images/app/reset-btn-curso.png')">
                                    <h3>Recomeçar do zero</h3> 
                                  </a>
                                </div>
                              </div>                             
                            </div>
                          </div>

                          <div v-else class="text-center">
                            <h3><b-icon
                              icon="gear-fill"
                              animation="spin"
                            /> Carregando</h3>
                          </div>
                          <!-- /con-info-curso-actions-novo -->
                          

                          <!--?-->
                          <div class="con-info-curso-docentes-novo d-none">
                            <div
                              class="con-info-curso-docentes-body fast-menu-lives-aluno-iuea"
                            >
                              <h4 class="fast-iuea-color">
                                Mentores
                              </h4>

                              <ul class="con-info-curso-list">
                                <li
                                  v-for="professor in fastCursoProfessores"
                                  :key="professor.id_professor"
                                  class="con-info-curso-list-item d-flex align-items-center"
                                >
                                  <img
                                    class="avatar"
                                    :src="ajustaLinkImageUser(professor.image)"
                                  >
                                  <span>
                                    {{ professor.first_name }} {{ professor.last_name }}
                                    <br>

                                    <small v-if="professor.licenciatura == '1'">Graduado</small>
                                    <small v-else-if="professor.licenciatura == '2'">Especialista</small>
                                    <small v-else-if="professor.licenciatura == '3'">Mestre</small>
                                    <small v-else-if="professor.licenciatura == '4'">Doutor</small>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <!--/?-->

                          <div class="con-info-curso-docentes-novo fast-plataforma-iuea-hide">
                            <div class="con-info-curso-docentes-header text-wrap">
                              <h4 v-if="!getUrlParameter('programa') && !getUrlParameter('turma')">
                                Tutor do curso
                              </h4>
                              <h4 v-else>
                                Tutor
                              </h4>
                              <div
                                v-if="fastCursoTutor.tutor"
                                class="con-info-curso-tutor d-flex align-items-center"
                              >
                                <img
                                  class="avatar"
                                  :src="ajustaLinkImageUser(fastCursoTutor.image)"
                                >
                                <span>{{ fastCursoTutor.first_name }} {{ fastCursoTutor.last_name }}</span>
                              </div>
                              <div
                                v-else
                                class="con-info-curso-tutor d-flex align-items-center"
                              >
                                <p>Nenhum tutor cadastrado</p>
                              </div>
                            </div>
                            <div class="con-info-curso-docentes-body text-wrap">
                              <h4 v-if="!getUrlParameter('programa') && !getUrlParameter('turma')">
                                Professores do Plano de Estudo
                              </h4>
                              <h4 v-else>
                                Professores da unidade curricular
                              </h4>
                              <ul class="con-info-curso-list">
                                <li
                                  v-for="professor in fastCursoProfessores"
                                  :key="professor.id_professor"
                                  class="con-info-curso-list-item d-flex align-items-center"
                                >
                                  <img
                                    v-if="professor.image"
                                    class="avatar"
                                    :src="ajustaLinkApiAntiga(ajustaLinkImageUser(professor.image))"
                                  >
                                  <img
                                    v-else
                                    class="avatar"
                                    src="https://toussaint.concurseiro.com/css/images/avatar.svg"
                                  >
                                  <span>
                                    {{ professor.first_name }} {{ professor.last_name }}
                                    <br>

                                    <small v-if="professor.licenciatura == '1'">Graduado</small>
                                    <small v-else-if="professor.licenciatura == '2'">Especialista</small>
                                    <small v-else-if="professor.licenciatura == '3'">Mestre</small>
                                    <small v-else-if="professor.licenciatura == '4'">Doutor</small>
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div class="con-info-curso-docentes-body text-wrap"
                              v-if="fastCursoDisciplinas.length"
                            >
                              <h4>Disciplinas</h4>
                              <ul class="con-info-curso-list">
                                <li
                                  v-for="d in fastCursoDisciplinas"
                                  :key="d.id_area_disciplina_plataforma"
                                  class="con-info-curso-list-item d-flex align-items-center"
                                >
                                  <span>
                                    <b-icon-bookmarks-fill />
                                    {{ d.descricao }}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div class="con-info-curso-start mt-3">
                            <span
                              v-if="fastCursoCertificado"
                              style
                              class="btn-novo btn-primary btn-lg"
                              @click="showModal('modalStatusCertificado')"
                            >Status do certificado</span>

                            <!-- #modal-status-certificado -->
                            <div
                              id="modal-status-certificado"
                              class="modal fade"
                              tabindex="-1"
                              role="dialog"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div
                                class="modal-dialog modal-lg"
                                role="document"
                              >
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h3 class="modal-title">
                                      Status do certificado
                                    </h3>
                                    <button
                                      type="button"
                                      class="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <div
                                      id="divModalStatusCertificado"
                                      class="col-12"
                                    >
                                      <span
                                        class="text-danger"
                                      >Não é possível emitir o certificado, pois sua nota não atingiu a média do curso.</span>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button
                                      id="btnVisualizarCertificado"
                                      type="button"
                                      class="btn btn-primary"
                                      style="display: none"
                                    >
                                      Download
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- #modal-status-certificado -->
                          </div>

                          <!-- con-aulas-curso -->
                          <div v-if="fastDomain == 'iuea.fastead.com'">
                            <section class="con-aulas-curso-novo mt-4" style="overflow: auto">
                              <div class="con-aulas-curso-header">
                                <div class="d-flex align-items-center">
                                  <h3
                                    class="fast-menu-lives-aluno-fast"
                                  >
                                    Aulas ({{ fastCursoAulasFiltro.length }})
                                  </h3>
                                  <h3 class="d-none fast-menu-lives-aluno-iuea" />
                                </div>
                              </div>
                              <div class="con-aulas-curso-body">
                                <ul class="con-aulas-curso-list">
                                  <li
                                    v-for="aula in pageOfItems"
                                    :key="aula.id_aula"
                                    :class="aula.classe"
                                  >
                                    <div class="d-flex align-items-center">
                                      <div
                                        class="con-aulas-curso-list-item-icon d-flex align-items-center justify-content-center"
                                      >
                                        <b-icon-play
                                          variant="white"
                                          font-scale="2"
                                        />
                                      </div>

                                      <span class="con-aulas-curso-list-item-text w-100">
                                        <a
                                          v-if="aula.status"
                                          :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos/curso/' + fastCursoPlataforma.id_curso + '/aula/' + aula.id_aula"
                                        >
                                          <h4 class="fast-iuea-color">{{ aula.titulo_aula }}</h4>
                                        </a>
                                        <a
                                          v-else
                                          href="#"
                                          @click.prevent="acessaNovaAula(aula)"
                                        >
                                          <h4 class="fast-iuea-color">{{ aula.titulo_aula }}</h4>
                                        </a>
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </section>
                            <div class="col-12 text-center mt-4 fast-plataforma-iuea-hide">
                              <Pagination
                                :page-size="50"
                                :items="fastCursoAulasFiltro"
                                @changePage="pageOfItems = $event"
                              />
                            </div>
                          </div>
                          <!-- /con-aulas-curso -->
                        </div>
                      </div>
                    </section>
                    <!-- con-aulas-curso -->
                    <div class="row mt-4">
                      <div
                        v-if="fastDomain != 'iuea.fastead.com'"
                        class="col-sm-12 col-md-12 col-lg-12"
                      >
                        <section class="con-aulas-curso-novo mt-4" style="overflow: auto">
                          <div class="con-aulas-curso-header">
                            <div class="d-flex align-items-center">
                              <h5
                                class="text-secondary fast-menu-lives-aluno-fast"
                              >
                                Lista de Aulas ({{ fastCursoAulasFiltro.length }})
                              </h5>
                              <h5 class="text-secondary d-none fast-menu-lives-aluno-iuea" />
                            </div>
                          </div>
                          <div class="con-aulas-curso-body">
                            <ul class="con-aulas-curso-list">
                              <li
                                v-for="aula in pageOfItems"
                                :key="aula.id_aula"
                                :class="aula.classe"
                              >
                                <div class="d-flex align-items-center">
                                  <div
                                    class="con-aulas-curso-list-item-icon d-flex align-items-center justify-content-center"
                                    role="button"
                                  >
                                    <b-icon-play font-scale="2"
                                    v-if="aula.status"
                                      :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos/curso/' + fastCursoPlataforma.id_curso + '/aula/' + aula.id_aula"
                                      @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos/curso/' + fastCursoPlataforma.id_curso + '/aula/' + aula.id_aula)"
                                    />
                                    <b-icon-play font-scale="2"
                                    v-else
                                      href="#"
                                      @click.prevent="acessaNovaAula(aula)"
                                    />
                                  </div>

                                  <span
                                    v-if="!getUrlParameter('turma') && !getUrlParameter('programa')"
                                    class="con-aulas-curso-list-item-text w-100"
                                  >
                                    <a
                                      v-if="aula.status"
                                      :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos/curso/' + fastCursoPlataforma.id_curso + '/aula/' + aula.id_aula"
                                    >
                                      <h4>{{ aula.titulo_aula }}</h4>
                                    </a>
                                    <a
                                      v-else
                                      href="#"
                                      @click.prevent="acessaNovaAula(aula)"
                                    >
                                      <h4>{{ aula.titulo_aula }}</h4>
                                    </a>
                                  </span>
                                  <span
                                    v-else
                                    class="con-aulas-curso-list-item-text w-100"
                                  >
                                    <a
                                      v-if="aula.status"
                                      :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos/curso/' + fastCursoPlataforma.id_curso + '/aula/' + aula.id_aula + '?turma=' + base64encode(parseInt(base64decode(getUrlParameter('turma')))) + '&programa=' + base64encode(parseInt(base64decode(getUrlParameter('programa')))) + '&matricula=' + base64encode(parseInt(base64decode(getUrlParameter('matricula'))))"
                                    >
                                      <h4>{{ aula.titulo_aula }}</h4>
                                    </a>
                                    <a
                                      v-else
                                      href="#"
                                      @click.prevent="acessaNovaAula(aula)"
                                    >
                                      <h4>{{ aula.titulo_aula }}</h4>
                                    </a>
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </section>
                        <div class="col-12 text-center mt-4 fast-plataforma-iuea-hide">
                          <Pagination
                            :page-size="50"
                            :items="fastCursoAulasFiltro"
                            @changePage="pageOfItems = $event"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- /con-aulas-curso -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
    <VueHtml2pdf
      ref="html2Pdf"
      :show-layout="false"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1100"
      filename="Certificado"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
    >
      <section slot="pdf-content">
        <div v-html="fastCertificadoUsuario.html_certificado" />
      </section>
    </VueHtml2pdf>
    <!-- Modals -->
    <modal
      name="modalStatusCertificado"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
      :width="modalWidthSm"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Status do certificado</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalStatusCertificado')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>

        <div class="col-12 mt-4 pl-4 pr-4">
          <h6
            v-if="fastCursoAprovacao == 'aprovado'"
            class="text-success"
          >
            {{ fastCursoStatusAprovacao }}
          </h6>
          <h6
            v-else-if="fastCursoAprovacao == 'reprovado'"
            class="text-danger"
          >
            {{ fastCursoStatusAprovacao }}
          </h6>

          <div
            v-if="fastCertificadoUsuario.id_certificado"
            class="mt-4"
          >
            <button
              class="btn btn-success"
              @click="emiteCertificadoUsuario"
            >
              Gerar certificado
            </button>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalReiniciarCurso"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
      :width="modalWidthSm"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-12 pl-4 pr-4">
          <h4>Reiniciar curso?</h4>
        </div>

        <div class="col-12 mt-4 pl-4 pr-4">
          <button
            class="btn btn-danger mr-2"
            @click="reiniciaFastUsuarioCurso()"
          >
            <small>Confirmar</small>
          </button>
          <button
            class="btn btn-secondary"
            @click="hideModal('modalReiniciarCurso')"
          >
            <small>Cancelar</small>
          </button>
        </div>
      </div>
    </modal>
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../../components/Pagination";
import VueHtml2pdf from "vue-html2pdf"; //https://www.npmjs.com/package/vue-html2pdf

export default {
  name: "HomeInternoAlunoCursoDetalhes",
  components: {
    Pagination,
    VueHtml2pdf,
  },
  mixins: [methods],
  data: function () {
    return {
      fastTemplate: settings.fastTemplate,
      fastDomain: settings.fastDomain,
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // FastCurso
      fastCursoPlataforma: {
        acesso: "",
        carga_horaria: "",
        data_disponivel: "",
        data_expiracao: "",
        detalhe_curso: "",
        id_curso: 0,
        id_plataforma: 0,
        id_registro_curso_plataforma: "",
        id_requisicao: "",
        id_usuario_responsavel: "",
        libera_automatica: "",
        logo_curso: "",
        multidisciplinar: "",
        nome_curso: "",
        origem: "",
        publicado: "",
        quantidade: "",
      },
      fastCursoDetalhes: {},
      fastCursoTutor: {},
      fastCursoProfessores: [],
      fastCursoPorcentagem: 0,
      fastCursoCertificado: false,
      fastCursoAprovacao: "",
      fastCursoStatusAprovacao: "",
      fastCursoAulasTotal: [],
      fastCursoAulasFiltro: [],
      fastCursoIniciado: false,
      pageOfItems: [],
      // FasAula
      fastAulaAtual: {
        id_aula: "",
      },
      fastCertificadoUsuario: {
        id_certificado: "",
        id_curso: 0,
        html_certificado: "",
      },
      fastUsuarioCurso: [],
      fastRedirecionaAula: false,
      // Disciplinas
      fastCursoDisciplinas: [],
      fastCursoReiniciar: 0,
      fastCarregamentoCompleto: false,
      iniciandoCursoLoading: false
    };
  },
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma && this.$route.params.id_curso) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
        .then(() => {
          if (this.getUrlParameter('programa') && this.getUrlParameter('turma')) {
            this.exibeFastCursoDetalhesSecretaria();
          } else {
            this.exibeFastCursoDetalhes();
          }
          this.getFastCursoPlataforma();
          this.getFastCertificado();
          this.exibeAulasCurso();

          this.exibeTutorPlanoCurso();
          this.exibeFastCursoProfessores();

          this.buscaEstatisticasUsuarioCurso()
            .then((u) => {
              this.fastUsuarioCurso = u;
              if (u[0]) this.fastCursoReiniciar = u[0].reiniciar              
            })
            .catch((e) => {
              console.log("then", e);
            });
          if (this.fastDomain != "iuea.fastead.com")
            this.getFastDisciplinasCurso();
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getFastCursoPlataforma() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_plataforma/lista_curso_aluno?id_usuario=&id_plataforma=" +
            this.$route.params.id_plataforma +
            "&id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let objCurso = Array.from(json);
        if (objCurso.length) {
          objCurso[0].nome_curso = objCurso[0].nome_curso ? objCurso[0].nome_curso : objCurso[0].nome_curso_mae
          objCurso[0].detalhe_curso = objCurso[0].detalhe_curso ? objCurso[0].detalhe_curso : objCurso[0].detalhe_curso_mae
          objCurso[0].carga_horaria = objCurso[0].carga_horaria ? objCurso[0].carga_horaria : objCurso[0].carga_horaria_mae
          objCurso[0].logo_curso = objCurso[0].logo_curso ? objCurso[0].logo_curso : objCurso[0].logo_curso_mae

          this.fastCursoPlataforma = objCurso[0];
        } else {
          this.fastCursoPlataforma = [];
        }

      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastCertificado() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_usuario_curso/verifica_certificado?id_curso=" +
            this.$route.params.id_curso +
            "&id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastCursoPorcentagem = obj[0].status.split(" ").pop();
          let certificado = obj[0].status.split(",")[0];

          if (certificado == "Curso com certificado") {
            this.fastCursoCertificado = true;
            this.fastCursoAprovacao = obj[0].status.split(" ")[3];

            // Aprovado com nota
            if (this.fastCursoAprovacao == "aprovado") {
              this.fastCursoStatusAprovacao =
                "Parabéns! Você  atingiu a média do curso.";

              // Busca certificado e prepara botão de emissão
              this.getCertificadoUsuario();

              // Reprovado devido a nota
            } else if (this.fastCursoAprovacao == "reprovado") {
              this.fastCursoStatusAprovacao =
                "Não é possível emitir o certificado, pois sua nota não atingiu a média do curso.";

              // Certificado não depende de nota
            } else if (this.fastCursoAprovacao == "progresso") {
              // Completou 100%
              if (parseInt(this.fastCursoPorcentagem) == 100) {
                this.fastCursoStatusAprovacao =
                  "Parabéns! Você completou o curso.";
                // Busca certificado e prepara botão de emissão
                this.getCertificadoUsuario();
              } else {
                this.fastCursoStatusAprovacao =
                  "Para emitir o certificado você deve completar o curso.";
              }
            }
          }
          //console.log("getFastCertificado", obj);
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getCertificadoUsuario() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_usuario_curso/lista_certificado?id_curso=" +
            this.$route.params.id_curso +
            "&id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastCertificadoUsuario = obj[0];
          let certificado = this.fastCertificadoUsuario.html_certificado;
          certificado = certificado.replace(
            "[nome_aluno]",
            this.retornaSessao(settings.fastSessaoUsuario).First_name +
              " " +
              this.retornaSessao(settings.fastSessaoUsuario).Last_name
          );
          certificado = certificado.replace(
            "[nome_curso]",
            this.fastCursoDetalhes.nome_curso
          );
          certificado = certificado.replace(
            "[carga_horaria]",
            this.fastCursoDetalhes.carga_horaria + " horas"
          );
          certificado = certificado.replace(
            "[data_inicio]",
            new Date(this.fastUsuarioCurso[0].data_inicio_curso)
              .toLocaleString()
              .split(" ")[0]
          );

          certificado = certificado.replace("height: 600px;", "");

          let data_fim_atividade = "";
          this.fastUsuarioCurso.forEach((d, index) => {
            if (index == 0) {
              data_fim_atividade = new Date(d.data_fim_atividade);
            } else {
              if (new Date(d.data_fim_atividade) > new Date(data_fim_atividade))
                data_fim_atividade = d.data_fim_atividade;
            }
          });
          certificado = certificado.replace(
            "[data_conclusao]",
            new Date(data_fim_atividade).toLocaleString().split(" ")[0]
          );
          this.fastCertificadoUsuario.html_certificado = certificado;
        }
        //console.log("getCertificadoUsuario", json);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    emiteCertificadoUsuario() {
      this.$refs.html2Pdf.generatePdf();
    },
    async buscaEstatisticasUsuarioCurso() {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_usuario_curso/estatisticas_usuario?id_usuario_aluno=" +
              this.retornaSessao(settings.fastSessaoUsuario).Id_Usuario +
              "&id_plataforma=" +
              this.$route.params.id_plataforma +
              "&id_curso=" +
              this.$route.params.id_curso,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          console.log("buscaEstatisticasUsuarioCurso", json);
          if (json.length) {
            resolve(json);
          } else {
            reject(0);
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    async reiniciaFastUsuarioCurso() {
      
      let fast_curso_plataforma = {
        id_plataforma: this.$route.params.id_plataforma,
        id_curso: this.$route.params.id_curso,
      };
      this.$store.state.fastCarregando = true
      this.iniciandoCursoLoading = true
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_usuario_curso/reinicia",
          this.fastAjaxOptions("POST", JSON.stringify(fast_curso_plataforma))
        );
        let json = await resp.json();
        //console.log(json);
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Curso reiniciado com sucesso",
        });
        this.fastRedirecionaAula = true;
        this.exibeAulasCurso();
        //this.hideModal("modalReiniciarCurso");
        //this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
        this.iniciandoCursoLoading = false
      }
    },
    async iniciaFastUsuarioCurso() {
      this.$store.state.fastCarregando = true
      this.iniciandoCursoLoading = true
      let fast_curso_plataforma = {
        id_plataforma: this.$route.params.id_plataforma,
        id_curso: this.$route.params.id_curso,
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_usuario_curso/reinicia",
          this.fastAjaxOptions("POST", JSON.stringify(fast_curso_plataforma))
        );
        let json = await resp.json();
        if (this.getUrlParameter('turma') && this.getUrlParameter('programa')) {
          // Decodifica base64, converte pra INT e codifica novamente para prevenir XSS
          this.$router.push("/plataforma/" +
          this.$route.params.id_plataforma +
          "/aluno/meus-cursos/curso/" +
          this.fastCursoPlataforma.id_curso +
          "/aula/" +
          this.fastAulaAtual.id_aula + "?turma=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('turma')))) + "&programa=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('programa')))) + '&matricula=' + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('matricula')))));
        } else {
          this.$router.push("/plataforma/" +
          this.$route.params.id_plataforma +
          "/aluno/meus-cursos/curso/" +
          this.fastCursoPlataforma.id_curso +
          "/aula/" +
          this.fastAulaAtual.id_aula);
        }
        
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false
        this.iniciandoCursoLoading = false
      }
    },
    async exibeAulasCurso() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_usuario_curso_aula/lista?id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);

        this.exibeAulasCursoPendentes(obj);
        //console.log("exibeAulasCurso", json);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async exibeAulasCursoPendentes(aulas) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_usuario_curso_aula/lista_pendentes_curso?id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          if (aulas.length) {
            let aulaTotal = obj.concat(aulas);
            aulaTotal.forEach((a) => {
              if (a.status) {
                if (a.status == "E") {
                  a.classe = "con-aulas-curso-list-item em_andamento";
                } else if (a.status == "F") {
                  a.classe = "con-aulas-curso-list-item concluido";
                }
              } else {
                a.classe = "con-aulas-curso-list-item";
              }
            });
            this.fastCursoAulasTotal = aulaTotal.sort(this.sortArrayAulas);
            this.fastCursoAulasFiltro = aulaTotal.sort(this.sortArrayAulas);
            this.fastCursoIniciado = true;
          } else {
            obj.forEach((a) => {
              if (a.status) {
                if (a.status == "E") {
                  a.classe = "con-aulas-curso-list-item em_andamento";
                } else if (a.status == "F") {
                  a.classe = "con-aulas-curso-list-item concluido";
                }
              } else {
                a.classe = "con-aulas-curso-list-item";
              }
            });
            this.fastCursoAulasTotal = obj;
            this.fastCursoAulasFiltro = obj;
          }
        } else {
          let aulas_finalizada = 0;
          if (aulas.length) {
            aulas.forEach((a) => {
              if (a.status) {
                if (a.status == "E") {
                  a.classe = "con-aulas-curso-list-item em_andamento";
                } else if (a.status == "F") {
                  a.classe = "con-aulas-curso-list-item concluido";
                  aulas_finalizada++;
                }
              } else {
                a.classe = "con-aulas-curso-list-item";
              }
            });
            if (aulas_finalizada == aulas.length) {
              this.promiseFinalizaCurso()
                .then(() => console.log("Curso finalizado"))
                .catch(() => console.log("Erro ao finalizar curso"));
            }
            this.fastCursoAulasTotal = aulas;
            this.fastCursoAulasFiltro = aulas;
            this.fastCursoIniciado = true;
          } else {
            this.fastCursoAulasTotal = [];
            this.fastCursoAulasFiltro = [];
          }
        }

        // Aula em execução tem prioridade
        this.fastCursoAulasTotal.some((a) => {
          if (a.status) {
            if (a.status == "E") {
              this.fastAulaAtual = a;
              return true;
            }
          }
        });
        // Pega a aula próxima a uma uma finalizada
        if (!this.fastAulaAtual.id_aula) {
          this.fastCursoAulasTotal.some((a, index) => {
            if (a.status) {
              if (a.status == "F") {
                if (this.fastCursoAulasTotal[index + 1]) {
                  this.fastAulaAtual = this.fastCursoAulasTotal[index + 1];
                  return true;
                }
              }
            }
          });
        }
        // Pega a primeira aula
        if (!this.fastAulaAtual.id_aula) {
          if (this.fastCursoAulasTotal.length)
            this.fastAulaAtual = this.fastCursoAulasTotal[0];
        }

        if (this.fastRedirecionaAula) {
          if (this.getUrlParameter('turma') && this.getUrlParameter('programa')) {
            // Decodifica base64, converte pra INT e codifica novamente para prevenir XSS
            this.$router.push("/plataforma/" +
            this.$route.params.id_plataforma +
            "/aluno/meus-cursos/curso/" +
            this.$route.params.id_curso +
            "/aula/" +
            this.fastAulaAtual.id_aula + "?turma=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('turma')))) + "&programa=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('programa')))) + '&matricula=' + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('matricula')))));
          } else {
            this.$router.push("/plataforma/" +
            this.$route.params.id_plataforma +
            "/aluno/meus-cursos/curso/" +
            this.$route.params.id_curso +
            "/aula/" +
            this.fastAulaAtual.id_aula);
          }
          
        } else {
          // Informa ao component pai para interromper o loading
          this.$store.state.fastCarregando = false;
        }

        this.fastCarregamentoCompleto = true

        //console.log("exibeAulasCursoPendentes", this.fastAulaAtual);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async promiseFinalizaCurso() {
      return new Promise(async (resolve, reject) => {
        let fast_curso_plataforma = {
          id_curso: this.$route.params.id_curso,
          id_plataforma: this.$route.params.id_plataforma
        };
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_usuario_curso/finaliza",
            this.fastAjaxOptions("POST", JSON.stringify(fast_curso_plataforma))
          );
          let json = await resp.json();
          resolve(true);
        } catch (e) {
          reject(e);
        }
      });
    },
    sortArrayAulas(a, b) {
      const sequenciaA = a.sequencia;
      const sequenciaB = b.sequencia;

      let comparison = 0;
      if (sequenciaA > sequenciaB) {
        comparison = 1;
      } else if (sequenciaA < sequenciaB) {
        comparison = -1;
      }
      return comparison;
    },
    async acessaNovaAula(aula) {
      if (this.fastCursoIniciado) {
        let fast_usuario_curso_aula = {
          id_aula: aula.id_aula,
          id_plataforma: this.$route.params.id_plataforma,
          fast_usuario_curso: {
            id_curso: this.$route.params.id_curso,
          },
        };
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_usuario_curso_aula/insere_aula",
            this.fastAjaxOptions(
              "POST",
              JSON.stringify(fast_usuario_curso_aula)
            )
          );
          let json = await resp.json();
          let obj = Array.from(json);
          if (obj.length) {
            if (this.getUrlParameter('turma') && this.getUrlParameter('programa')) {
            // Decodifica base64, converte pra INT e codifica novamente para prevenir XSS
            this.$router.push("/plataforma/" +
            this.$route.params.id_plataforma +
            "/aluno/meus-cursos/curso/" +
            this.fastCursoPlataforma.id_curso +
            "/aula/" +
            aula.id_aula + "?turma=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('turma')))) + "&programa=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('programa')))) + '&matricula=' + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('matricula')))));
          } else {
            this.$router.push("/plataforma/" +
              this.$route.params.id_plataforma +
              "/aluno/meus-cursos/curso/" +
              this.fastCursoPlataforma.id_curso +
              "/aula/" +
              aula.id_aula);
          }
            
          } else {
            this.$notify({
              group: "foo",
              type: "error",
              duration: 5000,
              speed: 1000,
              title: "Status",
              text: "Erro ao iniciar aula",
            });
          }
        } catch (e) {
          console.log("Erro", e);
        }
      } else {
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text:
            "Você deve iniciar o plano de estudo antes de ver as aulas avulsas.",
        });
      }
    },
    continuarCurso() {
      if (this.fastAulaAtual.status) {
        if (this.getUrlParameter('turma') && this.getUrlParameter('programa')) {
            // Decodifica base64, converte pra INT e codifica novamente para prevenir XSS
            this.$router.push("/plataforma/" +
            this.$route.params.id_plataforma +
            "/aluno/meus-cursos/curso/" +
            this.fastCursoPlataforma.id_curso +
            "/aula/" +
            this.fastAulaAtual.id_aula + "?turma=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('turma')))) + "&programa=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('programa')))) + '&matricula=' + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('matricula')))));
          } else {
            this.$router.push("/plataforma/" +
          this.$route.params.id_plataforma +
          "/aluno/meus-cursos/curso/" +
          this.fastCursoPlataforma.id_curso +
          "/aula/" +
          this.fastAulaAtual.id_aula);
          }
        
      } else {
        this.acessaNovaAula(this.fastAulaAtual);
      }
    },
    // Detalhes do curso
    async exibeTutorPlanoCurso() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_professor/lista_tutor?id_plataforma=" +
            this.$route.params.id_plataforma +
            "&id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) this.fastCursoTutor = obj[0];
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async exibeFastCursoDetalhes() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso/lista_destalhes?id_curso=" +
            this.$route.params.id_curso +
            "&id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let objCurso = Array.from(json);
        if (objCurso.length) {
          objCurso[0].nome_curso = objCurso[0].nome_curso ? objCurso[0].nome_curso : objCurso[0].nome_curso_mae
          objCurso[0].detalhe_curso = objCurso[0].detalhe_curso ? objCurso[0].detalhe_curso : objCurso[0].detalhe_curso_mae
          objCurso[0].carga_horaria = objCurso[0].carga_horaria ? objCurso[0].carga_horaria : objCurso[0].carga_horaria_mae
          objCurso[0].logo_curso = objCurso[0].logo_curso ? objCurso[0].logo_curso : objCurso[0].logo_curso_mae
          this.fastCursoDetalhes = objCurso[0];
        }
        //console.log("exibeFastCursoDetalhes", json);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async exibeFastCursoDetalhesSecretaria() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso/lista_destalhes_secretaria?id_curso=" +
            this.$route.params.id_curso +
            "&id_plataforma=" +
            this.$route.params.id_plataforma +            
            "&id_programa_turma=" +
            parseInt(this.base64decode(this.getUrlParameter('programa'))),
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let objCurso = Array.from(json);
        if (objCurso.length) {
          objCurso[0].nome_curso = objCurso[0].nome_curso ? objCurso[0].nome_curso : objCurso[0].nome_curso_mae
          objCurso[0].detalhe_curso = objCurso[0].detalhe_curso ? objCurso[0].detalhe_curso : objCurso[0].detalhe_curso_mae
          objCurso[0].carga_horaria = objCurso[0].carga_horaria ? objCurso[0].carga_horaria : objCurso[0].carga_horaria_mae
          objCurso[0].logo_curso = objCurso[0].logo_curso ? objCurso[0].logo_curso : objCurso[0].logo_curso_mae
          this.fastCursoDetalhes = objCurso[0];
        }
        //console.log("exibeFastCursoDetalhes", json);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async exibeFastCursoProfessores() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_professor/lista?id_plataforma=" +
            this.$route.params.id_plataforma +
            "&id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastCursoProfessores = obj;
        }
        //console.log("exibeFastCursoProfessores", json);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Disciplinas
    async getFastDisciplinasCurso() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_disciplina_plataforma/lista?id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);

        if (obj.length) {
          this.fastCursoDisciplinas = obj;
        } else {
          this.fastCursoDisciplinas = [];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
  },
};
</script>

<style scope>
</style>
